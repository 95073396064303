<template>
    <div class="calendar-subview">
      <teleport to="#header-action">
        <Btn
          v-if="helperService.userHasPermission('essaievenement_oadd')"
          class="action-btn"
          color="primary"
          text="Export des données"
          @click="exportDatas()"
        />
      </teleport>

      <div>
        <InputMedia
          ref="inputFile"
          class="input-file--import"
          textInfo="Fichier au format .xls ou .xlsx"
          label="Importer un fichier"
          :displayFileName="false"
          type="import"
          :mediaKey="componentKey"
          @update:modelValue="analyse($event)"
        />
      </div>

      <List
        :key="componentKey"
        :api="list.api"
        :header="list.headers"
        :items="list.col"
        :item-key="list.key"
        :target="list.target"
        disableActions
        disableGroupActions
        @selected-items="setSelectedItems($event)"
      >

        <template v-slot:designation="{ item }">
          {{ item.notation.evenement.designation ?? item.notation.evenement.type.designation }}
        </template>

        <template v-slot:variable="{ item }">
          {{ item.variable ? item.variable.designation : item.designation }}
        </template>

        <template v-slot:cible="{ item }">
          {{ item.cible?.valeur }}
        </template>

        <template v-slot:organe="{ item }">
          {{ item.organes.length ? item.organes[0].designation : null }}
        </template>

        <template v-slot:date_realisation="{ item }">
          {{ helperService.displayDate(item.notation.evenement?.date_realisation) }}
        </template>

        <template v-slot:nb_notes="{ item }">
          {{ item?.nbr_notes ?? 0 }}
        </template>

      </List>
    </div>

    <!-- Modals realizeEvent -->
    <Modal
      title="Erreur d'importation du fichier"
      :active="modal.fileErrors"
      :data="modalData"
      @modal-close="closeModal('fileErrors')"
      size="full"
      no-scroll
    >
      <template v-slot:modal-body="{ data }">
        <template v-for="(sheet, index) in data" :key="index">
          <div class="sheet-errors">

            <!-- En-têtes -->
            <div v-if="Object.keys(sheet['en_tetes']).length>0" class="">
              <h4>Feuille {{ index+1 }} - En-têtes</h4>
              <div v-for="(errors, i) in sheet['en_tetes']" :key="i" class="error-block" :class="{'error-block--global': isNaN(parseInt(i, 10))}">
                <template v-if="!isNaN(parseInt(i, 10))">
                  <div class="error-block-key">Colonne {{ parseInt(i, 10) +1  }}</div>
                </template>
                <div class="error-block-values">
                  <template v-if="Array.isArray(errors)">
                    <div class="error-block-value" v-for="(error, j) in errors" :key="j">{{ error }}</div>
                  </template>
                  <template v-else>
                    <div class="error-block-value">{{ errors }}</div>
                  </template>
                </div>
              </div>
            </div>

            <!-- Lignes -->
            <div v-if="Object.keys(sheet['donnees']).length>0" class="">
              <div v-for="(errors, i) in sheet['donnees']" :key="i" class="">
                <h4>Feuille {{ index+1 }} - Ligne {{ parseInt(i, 10)+1 }}</h4>
                <div v-for="(error, j) in errors" :key="j" class="error-block">
                  <div class="error-block-key">Colonne {{ parseInt(j,10)+1 }}</div>
                  <div class="error-block-values">
                    <div class="error-block-value">{{ error[0] }}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </template>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="closeModal('fileErrors')" />
      </template>
    </Modal>
  </template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Modal from '@/components/layout/Modal.vue'
import InputMedia from '@/components/form/InputMedia.vue'

export default {
  name: 'EssaiImportExportListView',

  components: {
    Modal,
    List,
    Btn,
    InputMedia,
  },

  emits: ['updateEssais'],

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },

  data() {
    return {
      toExport: [],
      list: {
        api: {
          endpoint: `essai/${this.$route.params.id}/evenement/fiche/notation/variable`,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Désignation de la tâche expérimentale' },
          { label: 'Variable' },
          { label: 'Cible' },
          { label: 'Organe' },
          { label: 'Date de réalisation' },
          { label: 'Nombre de notes' },
        ],
        col: ['designation', 'variable', 'cible', 'organe', 'date_realisation', 'nb_notes'],
        key: 'id',
      },

      modalData: {},
      modal: {
        fileErrors: false,
      },
      componentKey: 0,
    }
  },

  mounted() {
    this.$store.dispatch('io/clear')
  },

  methods: {
    setSelectedItems(event) {
      let toExport = []
      if ('selected' in event) {
        toExport = event.selected.map((s) => s.id)
      }

      this.toExport = toExport
    },
    analyse(event) {
      const jeton = event?.jeton
      if (jeton) {
        this.fetchService.post(`essai/${this.$route.params.id}/evenement/fiche/notation/fichier/${jeton}/analyse`)
          .then((response) => {
            const fileDatas = response.data
            this.$store.dispatch('io/setJeton', jeton)
            let hasErrors = false
            const errors = fileDatas.map((d) => d.erreurs)
            errors.forEach((error) => {
              if (
                (error.en_tetes !== null && Object.keys(error.en_tetes).length > 0)
                || (error.donnees !== null && Object.keys(error.donnees).length > 0)
              ) {
                hasErrors = true
              }
            })
            if (hasErrors) {
              // openmodal
              this.openModal('fileErrors', errors)
              this.$store.dispatch('io/setErrors', errors)
            } else {
              this.$store.dispatch('io/setSheets', fileDatas)
              this.$router.push({ name: 'essaiImport' })
            }
          })
      }
    },
    exportDatas() {
      const datas = {}
      if (this.toExport.length > 0) {
        datas.variables = this.toExport
        this.fileService.downloadEssaiExport(`essai/${this.$route.params.id}/evenement/fiche/notation/fichier/export`, datas)
      } else {
        this.emitter.emit('alert', {
          type: 'warning',
          content: 'Au moins une variable doit être selectionnée.',
        })
      }
    },

    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    closeModal(modal) {
      this.modal[modal] = false
      this.$refs.inputFile.resetForm()
    },

  },
}
</script>

  <style lang="scss" scoped>
  .input-file--import {
    padding-right: $gutter-half;
    padding-left: 5.2rem + $gutter-half;
    @include v-padding($gutter-half);

    @include bp('sm') {
      display: flex;
      align-items: center;
      gap: $gutter;

      ::v-deep(label) {
        margin-bottom: 0;
        font-size: $font-size-big;
      }

      ::v-deep(.input) {
        flex-grow: 1;
        flex-direction: row;
        justify-content: flex-start;
        gap: $gutter-half;
      }

      ::v-deep(.input-file-filename) {
        margin-bottom: 0;
      }
      ::v-deep(.input-file-label) {
        margin-bottom: 0;
      }
    }

    @include bp($bp-expanded-container) {
      padding-right: $gutter;
      padding-left: 5.2rem + $gutter;
    }
  }
  .essai-statut {
    height: 15px;
    width: 15px;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    transform: rotate(45deg);

    &--filled {
      border: 1px solid $color-primary;
      background: $color-primary;
    }
  }

  .essai-bpe {
    height: 25px;
    width: 25px;
    border: 10px solid $color-primary;
    border-radius: 50% 50% 50% 50% / 12% 12% 88% 88%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: $font-size-smaller;
    font-weight: $font-weight-lighter;
  }

  .essai-check {
    height: 40px;
    width: 40px;
    border: 2px solid $color-primary;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary;
  }

  .sheet-errors {
    margin-top: - $gutter;

    @include bp('sm', true) {
      @include h-margin(- $gutter);
    }

    h4 {
      margin-top: $gutter;
      margin-bottom: 0;
      padding: $gutter-half;
      background-color: $color-gray-lightest;
      font-size: $font-size-bigger;
    }
  }

  .error-block {
    @include bp('xs') {
      display: flex;
      gap: $gutter-half;
    }

    padding: $gutter-half;
    border-bottom: 1px solid $color-gray-lightest;
  }

  .error-block-key {
    @include bp('xs', true) {
      margin-bottom: $gutter-quarter;
    }

    min-width: 12rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }

  .error-block-value {
    color: $color-gray-dark;

    & + & {
      margin-top: $gutter-quarter;
    }

    .error-block--global & {
      font-weight: $font-weight-semibold;
      font-style: italic;
    }
  }
  </style>
